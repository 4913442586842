<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>种植批次</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'plantSeedlings-add'">
          <el-button type="primary" size="small" @click="addPlantGrowSeedlings">新增种植批次</el-button>
          &nbsp;
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
                v-loading="spinning"
                :data="data"
                row-key="id"
            >
              <el-table-column
                  prop="code"
                  label="编码">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="plantVarietyName"
                  label="种植品种">
              </el-table-column>
              <el-table-column
                  prop="farmAreaName"
                  label="种植基地">
              </el-table-column>
              <el-table-column
                  prop="farmParcelName"
                  label="种植地块">
              </el-table-column>
              <el-table-column
                  prop="startTime"
                  label="种植日期">
                <template slot-scope="scope">
                  {{scope.row.startTime | dateFormatFull}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="endTime"
                  label="预计收获">
                <template slot-scope="scope">
                  {{scope.row.endTime | dateFormatFull}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="plantArea"
                  label="种植面积">
              </el-table-column>
              <el-table-column
                  prop="imgPathAll"
                  label="品种图片">
                <template slot-scope="scope">
                  <img v-if="scope.row.imgPathAll" :src="scope.row.imgPathAll"
                       style="padding: 5px;height: 80px;width: auto" alt=""/>
                </template>
              </el-table-column>
              <el-table-column
                  fixed="right"
                  width="200"
                  label="操作"
                  align="center"
                  v-if="authVerify.verify('plantSeedlings-edit,plantSeedlings-delete')"
              >
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit-outline" size="mini" v-auth="'plantSeedlings-edit'"
                             @click="editPlantGrowSeedlings(scope.row)">编辑
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" v-auth="'plantSeedlings-delete'"
                             @click="deletePlantGrowSeedlings(scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10,20,30,40]"
                :current-page="currentPage"
                :page-size="pageSize"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
                :total="totalCount">
            </el-pagination>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
            title="新增种植批次"
            width="400"
            placement="right"
            :closable="false"
            :visible="addPlantGrowSeedlingsVisible"
            @close="addPlantGrowSeedlingsVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="plantGrowSeedlings" ref="plantGrowSeedlingsRefForm"
                          :rules="plantGrowSeedlingsRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="种植品种" prop="plantVarietyId">
                <a-select v-model="plantGrowSeedlings.plantVarietyId" placeholder="请选择种植品种">
                  <a-select-option v-for="obj in plantVarietyList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植基地" prop="farmAreaId">
                <a-select v-model="plantGrowSeedlings.farmAreaId" @change="farmAreaIdSelectChange" placeholder="请选择种植基地">
                  <a-select-option v-for="obj in farmAreaList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植地块" prop="farmParcelId">
                <a-select v-model="plantGrowSeedlings.farmParcelId" placeholder="请选择种植地块">
                  <a-select-option v-for="obj in farmParcelList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植面积" prop="plantArea">
                <a-input
                    placeholder="请输入育苗面积"
                    v-model="plantGrowSeedlings.plantArea"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="种植日期" prop="startTimeDate">
                <a-date-picker
                    show-time
                    style="width: 100%;"
                    v-model="plantGrowSeedlings.startTimeDate"
                    placeholder="请选择"></a-date-picker>
              </a-form-model-item>
              <a-form-model-item label="预计收获" prop="endTimeDate">
                <a-date-picker
                    show-time
                    style="width: 100%;"
                    v-model="plantGrowSeedlings.endTimeDate"
                    placeholder="请选择"></a-date-picker>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addPlantGrowSeedlingsVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toAddPlantGrowSeedlings">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
            title="编辑品种"
            width="400"
            placement="right"
            :closable="false"
            :visible="editPlantGrowSeedlingsVisible"
            @close="editPlantGrowSeedlingsVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="plantGrowSeedlings" ref="editPlantGrowSeedlingsRefForm"
                          :rules="plantGrowSeedlingsRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="种植品种" prop="plantVarietyId">
                <a-select v-model="plantGrowSeedlings.plantVarietyId">
                  <a-select-option v-for="obj in plantVarietyList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植基地" prop="farmAreaId">
                <a-select v-model="plantGrowSeedlings.farmAreaId" @change="farmAreaIdSelectChange">
                  <a-select-option v-for="obj in farmAreaList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植地块" prop="farmParcelId">
                <a-select v-model="plantGrowSeedlings.farmParcelId">
                  <a-select-option v-for="obj in farmParcelList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植面积" prop="plantArea">
                <a-input
                    v-model="plantGrowSeedlings.plantArea"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="种植日期" prop="startTimeDate">
                <a-date-picker
                    show-time
                    style="width: 100%;"
                    v-model="plantGrowSeedlings.startTimeDate"
                    :format="dateFormat"
                    placeholder="请选择"></a-date-picker>
              </a-form-model-item>
              <a-form-model-item label="预计收获" prop="endTimeDate">
                <a-date-picker
                    show-time
                    style="width: 100%;"
                    v-model="plantGrowSeedlings.endTimeDate"
                    :format="dateFormat"
                    placeholder="请选择"></a-date-picker>
              </a-form-model-item>

            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editPlantGrowSeedlingsVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toEditPlantGrowSeedlings">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
  </div>

</template>

<script>
    export default {
        data() {
            return {
                farmAreaList: [],
                farmParcelList: [],
                farmParcelMap: {},
                plantVarietyList: [],
                dateFormat: 'YYYY-MM-DD HH:mm:ss',
                addPlantGrowSeedlingsVisible: false,
                editPlantGrowSeedlingsVisible: false,
                currentPage: 1,
                pageSize: 10,
                totalCount: 0,
                spinning: true,
                data: [],
                plantGrowSeedlings: {
                    id: '',
                    plantVarietyId: undefined,
                    farmAreaId: undefined,
                    farmParcelId: undefined,
                    plantArea: '',
                    startTimeStr: '',
                    endTimeStr: '',
                    startTimeDate: this.moment(new Date()),
                    endTimeDate: this.moment(new Date()),
                },
                plantGrowSeedlingsRules: {
                    plantVarietyId: [{required: true, message: '请选择种植品种!', trigger: 'blur'}],
                    farmParcelId: [{required: true, message: '请选择种植农场地块!', trigger: 'blur'}],
                    farmAreaId: [{required: true, message: '请选择种植农场基地!', trigger: 'blur'}],
                    startTimeDate: [{required: true, message: '请选择种植日期!', trigger: 'blur'}],
                    endTimeDate: [{required: true, message: '请选择预计收获!', trigger: 'blur'}],
                    plantArea: [{required: true, message: '请输入种植面积 单位(亩)!', trigger: 'blur'}],
                }
            }
        },
        filters: {
            plantWayFilter: function (value) {
                if (!value) return "";
                if (value === 'MANUAL') {
                    return "人工";
                } else if (value === 'MACHINE') {
                    return "机器";
                }
            },
            statusFilter: function (value) {
                if (!value) return "";
                if (value === 'EXCELLENT') {
                    return "优";
                } else if (value === 'GOOD') {
                    return "良";
                }
            },
        },
        computed: {
            //判断是否显示Logo
            /*isShowImg() {
                if (this.plantGrowSeedlings.imgPath === "") {
                    return false;
                } else {
                    const reg = /null/;
                    return !reg.test(this.plantGrowSeedlings.imgPath)
                }
            }*/
        },
        mounted() {
            this.getPlantGrowSeedingsData();
        },
        created() {
            this.getFarmAreaList();
            this.getPlantVarietyList();
        },
        methods: {
            getFarmParcelGroupByFarmAreaId() {
                this.http.farmParcel.getFarmParcelGroupByFarmAreaId().then(response => {
                    if (response.data.status == 200) {
                        this.farmParcelMap = response.data.data;
                    } else {
                        this.$message.warning('数据获取失败');
                    }
                });
            },
            async farmAreaIdSelectChange() {
                this.plantGrowSeedlings.farmParcelId = '';
                this.farmParcelList = [];
                if(this.plantGrowSeedlings.farmAreaId != null && this.plantGrowSeedlings.farmAreaId != ''){
                    const {data: res} = await this.http.farmParcel.getFarmParcelGroupByFarmAreaId({
                        id: this.plantGrowSeedlings.farmAreaId
                    });
                    if (res.status !== 200) {
                        return this.$message.error("获取农场地块失败");
                    }else{
                        this.farmParcelList = res.data;
                    }
                }
            },
            getPlantVarietyList() {
                this.http.plantVariety.getPlantVarietyList().then(response => {
                    if (response.data.status == 200) {
                        this.plantVarietyList = response.data.data;
                    } else {
                        this.$message.warning('数据获取失败');
                    }
                });
            },
            getFarmAreaList() {
                this.http.farmArea.getFarmAreaList().then(response => {
                    if (response.data.status == 200) {
                        this.farmAreaList = response.data.data;
                    } else {
                        this.$message.warning('数据获取失败');
                    }
                });
            },

            getPlantGrowSeedingsData() {
                this.http.plantSeedlings.getPlantSeedlingsListPage({
                    size: this.pageSize,
                    current: this.currentPage,
                }).then(response => {
                    if (response.data.status == 200) {
                        this.data = response.data.data.records;
                        this.totalCount = response.data.data.total;
                        this.currentPage = response.data.data.current;
                    }
                    this.spinning = false;
                });
            },
            cleanPlantGrowSeedings() {
                this.plantGrowSeedlings = {
                    id: '',
                    plantVarietyId: undefined,
                    farmAreaId: undefined,
                    farmParcelId: undefined,
                    startTimeStr: '',
                    endTimeStr: '',
                    plantArea: '',
                    startTimeDate: this.moment(new Date()),
                    endTimeDate: this.moment(new Date()),
                };
            },
            addPlantGrowSeedlings() {
                this.addPlantGrowSeedlingsVisible = true;
                this.cleanPlantGrowSeedings();
                  this.$refs.plantGrowSeedlingsRefForm.resetFields()
                /* this.plantGrowSeedlings = {
                     startTimeDate: this.moment(new Date()),
                     endTimeDate: this.moment(new Date()),
                 };*/
            },
            toAddPlantGrowSeedlings() {
                this.$refs.plantGrowSeedlingsRefForm.validate(valid => {
                    if (valid) {
                        console.log("添加");
                        this.plantGrowSeedlings.startTimeStr = this.plantGrowSeedlings.startTimeDate.format('YYYY-MM-DD HH:mm:ss');
                        this.plantGrowSeedlings.endTimeStr = this.plantGrowSeedlings.endTimeDate.format('YYYY-MM-DD HH:mm:ss');
                        this.http.plantSeedlings.createdPlantSeedlings(this.plantGrowSeedlings).then(res => {
                            if (res.data.status == 200) {
                                this.$message.success(res.data.msg);
                                this.addPlantGrowSeedlingsVisible = false;
                            } else {
                                this.$message.warning(res.data.msg);
                            }
                            this.getPlantGrowSeedingsData();
                        });
                    }
                });

            },
            toEditPlantGrowSeedlings() {
                this.$refs.editPlantGrowSeedlingsRefForm.validate(valid => {
                    if (valid) {
                        this.plantGrowSeedlings.startTimeStr = this.plantGrowSeedlings.startTimeDate.format('YYYY-MM-DD HH:mm:ss');
                        this.plantGrowSeedlings.endTimeStr = this.plantGrowSeedlings.endTimeDate.format('YYYY-MM-DD HH:mm:ss');
                        this.http.plantSeedlings.updatePlantSeedlings(this.plantGrowSeedlings).then(res => {
                            if (res.data.status == 200) {
                                this.$message.success(res.data.msg);
                                this.editPlantGrowSeedlingsVisible = false;
                            } else {
                                this.$message.warning(res.data.msg);
                            }
                            this.getPlantGrowSeedingsData();
                        });
                    }
                });
            },
            editPlantGrowSeedlings(data) {
                this.cleanPlantGrowSeedings();
                this.plantGrowSeedlings = {
                    id: data.id,
                    plantVarietyId: data.plantVarietyId,
                    farmParcelId: data.farmParcelId,
                    farmAreaId: data.farmAreaId,
                    plantArea: data.plantArea,
                    startTimeStr: '',
                    endTimeStr: '',
                    startTimeDate: [],
                    endTimeDate: [],
                };
                if (data.startTime != null && data.startTime != '') {
                    this.plantGrowSeedlings.startTimeDate = this.moment(data.startTime);
                }
                if (data.endTime != null && data.endTime != '') {
                    this.plantGrowSeedlings.endTimeDate = this.moment(data.endTime);
                }
                if(data.farmParcelId == null || data.farmParcelId == ''){
                    this.farmAreaIdSelectChange();
                }else{
                    this.http.farmParcel.getFarmParcelGroupByFarmAreaId({
                        id: data.farmAreaId
                    }).then(response => {
                        if (response.data.status === 200) {
                            this.farmParcelList = response.data.data;
                        } else {
                            return this.$message.error("获取农场地块失败");
                        }
                    });
                }
                this.editPlantGrowSeedlingsVisible = true;
                  this.$refs.editPlantGrowSeedlingsRefForm.resetFields()
                // console.log(this.plantGrowSeedlings);
            },
            deletePlantGrowSeedlings(data) {
                this.$confirm({
                    title: '确定删除吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.http.plantSeedlings.deletePlantSeedlings({id: data.id})
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    this.getPlantGrowSeedingsData();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            })
                    },
                    onCancel() {
                    },
                });
            },
            handlePageChange(val) {
                this.spinning = true;
                this.currentPage = val;
                this.getPlantGrowSeedingsData();
            },
            handleSizeChange(val) {
                this.spinning = true;
                this.pageSize = val;
                this.getPlantGrowSeedingsData();
            },
        }

    }
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
